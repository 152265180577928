/*eslint-disable*/
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/Footer.js';
import SectionPricing from 'views/PricingPage/Sections/SectionPricing.js';
import SectionFeatures from 'views/PricingPage/Sections/SectionFeatures.js';
import pricingStyle from 'assets/jss/material-kit-pro-react/views/pricingStyle.js';
import logo from 'assets/img/logo.png';
import SectionWork from 'views/LandingPage/Sections/SectionWork';

const useStyles = makeStyles(pricingStyle);

export default function PricingPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			{/* <div style={{ backgroundImage: `url(${require('assets/img/hectorClassroom.png')})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> */}
			
			<div style={{ 
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${require('assets/img/hectorClassroom.png')})`, 
					backgroundSize: 'cover', 
					backgroundPosition: 'center', 
					color: 'white' 
				}}
			>
				<Header
					image={require('assets/img/logo.png')}
					brand={
						<img src={logo} alt='logo' style={{ width: 'auto', height: '4rem' }} />
					}
					links={<HeaderLinks dropdownHoverColor='info' />}
					color='transparent'
					changeColorOnScroll={{
						height: 300,
					}}
					style={{display: 'block', position: 'relative'}}
				/>

				<Parallax>
					<div className={classes.container} style={{ height: '100%'}}>
						<GridContainer 
						style={{ 
							position: 'relative',
							transform: window.innerWidth < 550 ? '0' : 'translateY(30%)',
						  }}
						>
							<GridItem
								md={10}
								sm={10}
								className={classNames(
									classes.mlAuto,
									classes.mrAuto,
									classes.textCenter
								)}
							>
								<h1 className={classes.title} 
								style={{ 
									paddingTop: '0px', 
									fontSize: window.innerWidth < 420 ? '40px' : '53px' 
								  }}
								>
									<span>Want to work with me&nbsp;</span> <span style={{ whiteSpace: 'nowrap'}}>1-on-1?</span>
								</h1>
								<h4>
								Nothing will teach you faster and better than 1-on-1 coaching. Courses and videos are great for general overviews, but you can&apos;t beat having a lesson tailored to you and your personality. Every client needs to work on something different to move forward – you are unique and have a specific set of problems that need to be personally addressed; if you work on those problems first, you will see the most immediate improvements. I&apos;ve taught men from all walks of life – from billion-dollar fund managers to programmers and even a Navy SEAL.
								</h4>
								<h4>If you&apos;re interested in working together, fill out the submission form below and we will schedule a call.
								If you want to see see how my coaching works, look further down on the page and watch a full coaching session I had with a client who was out doing day game, as well as testimonies from some other clients, detailing their experiences and results after working with me.
								</h4>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
			</div>
			
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					{/* <SectionPricing /> */}
					<hr />
					<SectionWork />
					<hr />
					<SectionFeatures />
					<hr />
				</div>
			</div>
			<Footer
				content={
					<div>
						<div className={classes.left}>
							<List className={classes.list}>
								<ListItem className={classes.inlineBlock}>
									<a href='/' target='_blank' className={classes.block}>
										About
									</a>
								</ListItem>
								<ListItem className={classes.inlineBlock}>
									<a href='/blog-post' className={classes.block}>
										My Philosophy
									</a>
								</ListItem>
							</List>
						</div>
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a
								href='https://www.linkedin.com/in/sorin-gifei-frontend/'
								target='_blank'
							>
								Sorin Gifei
							</a>{' '}
							for a better web.
						</div>
					</div>
				}
			/>
		</div>
	);
}
