// eslint-disable-next-line
import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { Radio, Box, Grid, Typography, TextField, FormControl,  RadioGroup, FormControlLabel } from '@material-ui/core';
import { CircularProgress }  from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/Footer.js';
import Button from 'components/CustomButtons/Button.js';
import productStyle from 'assets/jss/material-kit-pro-react/views/productStyle.js';
import logo from 'assets/img/logo.png';
import mge from 'assets/img/mge.png';
import product1 from 'assets/img/examples/product1.jpg';
import product2 from 'assets/img/examples/product2.jpg';
import product4 from 'assets/img/examples/product4.jpg';
import toast, { Toaster } from 'react-hot-toast';
import { getAuth, getIdToken } from 'firebase/auth';
import { useRef } from 'react';
import axios from 'axios';
import typographyStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.js';
import Rodal from 'rodal';
import PaymentForm from 'components/PaymentForm';
const useStyles = makeStyles(productStyle);
const useStylesTypography = makeStyles(typographyStyle);

export default function ProductPage2() {
	const classes = useStyles();
	const typographyClasses = useStylesTypography();
	const [modalVisible, setModalVisible] = React.useState(false);
	const [stripeElements, setStripeElements] = React.useState(null);
	const [stripe, setStripe] = React.useState(null);
	const loadingToastIdRef = React.useRef(null);

	const history = useHistory();
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const handleImageLoad = () => {
		setImageLoaded(true);
	};
	const hideModal = () => {
		setModalVisible(false);
		toast.dismiss();
		setStripeElements(null);
		setStripe(null);
		toast.error('Payment cancelled.');
	};

	// const showLoadingToast = (message) => {
	// 	if (!loadingToastIdRef.current) {
	// 		loadingToastIdRef.current = toast.loading(message);
	// 	} else {
	// 		toast.update(loadingToastIdRef.current, { render: message });
	// 	}
	// };

	// const hideLoadingToast = () => {
	// 	if (loadingToastIdRef.current) {
	// 		toast.dismiss(loadingToastIdRef.current);
	// 		loadingToastIdRef.current = null;
	// 	}
	// };

	const handleKeyDown = React.useCallback((event) => {
		if (event.keyCode === 27) {
			// ESC key
			hideModal();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	// const buyTest = async (productId) => {
	// 	const auth = getAuth();
	// 	const user = auth.currentUser;

		

	// 	// showLoadingToast('Payment Initialized! Please wait...');

	// 	getIdToken(user).then(async (idToken) => {
	// 		const res = await axios.post(
	// 			`${process.env.REACT_APP_API_URL}/initPayment`,
	// 			{
	// 				productId: productId,
	// 			},
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${idToken}`,
	// 				},
	// 			}
	// 		);

	// 		const stripe = window.Stripe(
	// 			process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
	// 		);
	// 		const elements = stripe.elements({
	// 			appearance: { theme: 'stripe' },
	// 			clientSecret: res.data.clientSecret,
	// 		});
	// 		const paymentElement = elements.create('payment');
	// 		paymentElement.mount('#payment-element');

	// 		setStripe(stripe);
	// 		setStripeElements(elements);
	// 		setLoading(false);

	// 	});
	// };

	// const pay = async (event) => {
	// 	event.preventDefault();
	// 	event.stopPropagation();

	// 	const auth = getAuth();
	// 	const user = auth.currentUser;

	// 	if (!user) {
	// 		history.push('/login-page');
	// 		return;
	// 	}
	// 	// showLoadingToast('Processing payment...');

	// 	const { error } = await stripe.confirmPayment({
	// 		elements: stripeElements,
	// 		confirmParams: {
	// 			payment_method_data: {
	// 				billing_details: {
	// 					name: user.name,
	// 					email: user.email,
	// 				},
	// 			},
	// 		},
	// 		redirect: 'if_required',
	// 	});

	// 	// hideLoadingToast();

	// 	if (error) {
	// 		if (error.type === 'card_error' || error.type === 'validation_error') {
	// 			toast.error(error.message);
	// 		} else {
	// 			toast.error('Error processing payment.');
	// 		}
	// 	} else {
	// 		// hideModal();
	// 		toast.success(
	// 			'Payment successful, please check your email/account for confirmation.'
	// 		);
	// 	}
	// };
	const images = [
		{
			original: mge,
			thumbnail: mge,
		},
		{
			original: product4,
			thumbnail: product4,
		},
		{
			original: product1,
			thumbnail: product1,
		},
		{
			original: product2,
			thumbnail: product2,
		},
	];
	const getting_text = [
		{
			title: "18 Professional Video Modules",
			old_price: "$697",
			new_price: "$297",
			description: "You can watch them anywhere: at work, at home, or on the go. Each video is 30 minutes to 1 hour long. They are filled with rich, detailed explanations that teach you what to do and say when you meet girls, but also why you do it, so that you become a truly attractive man that women chase, not “that guy” who just repeats lines (and gets rejected). ",
		},
		{
			title: "My Text-to-Date Guide",
			old_price: "$47",
			new_price: "FREE",
			description: "This video module helps you transition from meeting her to actually getting on a date (using an example from when I picked up a girl who worked at the bank). It includes real texts between me and her that start from when I met her to just before the date (and, yes, I did close the deal with her).  ",
		},
		{
			title: "Your Own FREE Copy of My Texting Book, What She Really Means: The Basics (and Not So Basics) of Texting Girls",
			old_price: "$39.99",
			new_price: "FREE",
			description: "If the text-to-date guide wasn’t enough, you also get my book on texting. It not only covers more about getting girls on a date, but EVERYTHING else about texting girls. When I say everything, I mean everything. You’d be hard-pressed to find some topic on texting girls that I haven’t covered in this book. ",
		},
		{
			title: "My “Locations” DLC for Meet Girls Everywhere",
			old_price: "$69",
			new_price: "FREE",
			description: "This was once an add-on course that taught you how to specifically approach and pickup girls at the beach, the mall, public transportation, and the gym. But I decided to add it into the main course (it used to cost $69 alone, but now you get it free, because I’m cool like that).",
		},
		{
			title: "Day-Game Case Study",
			old_price: "$27",
			new_price: "FREE",
			description: "This is a real story from when I picked up a girl walking around town and how the entire seduction went down, starting from the first meeting and ending in the bedroom (heads up: the story is VERY graphic in detail). ",
		},
		{
			title: "Hired-Gun Game Guide",
			old_price: "$67",
			new_price: "FREE",
			description: "How to get girls who are working at shops, restaurants, and bars. Getting girls who are working is complicated by the fact that they flirt you with you to make sales and get tips, so I give YOU tips that will separate you from every schmuck who tries to hit on the hot barista or bartender and gets you REAL results (because I’ve done it myself). ",
		},
		{
			title: "Schedule Skipper Guide",
			old_price: "$47",
			new_price: "FREE",
			description: "Most of the time when you meet girls, you will get their number and set up a date for another day. But occasionally you can get girls out on dates when you first meet them, even if they have other plans. You’ll have to be convincing, though. And I’ll help you do that.",
		},
		{
			title: "Approach Invitation Cheat Sheet",
			old_price: "$27",
			new_price: "FREE",
			description: "This is a PDF cheat-sheet from one of the modules in the main course that explains the signs women will give to you when they’re interested. You can easily save this on your phone and have with you as you walk around and make it easier to recognize these signals in real time. ",
		},
		{
			title: "Buzz-Off Signal Cheat Sheet",
			old_price: "$27",
			new_price: "FREE",
			description: "Same deal as the approach invitation list, but now for the signals women give when they’re definitely not interested, which makes it convenient for you to recognize when they aren’t worth your time, so you can focus on girls who already like you. ",
		}
	];
	return (
		<div className={classes.productPage} height="100%">
			<Toaster position="bottom-center" />
			<Rodal
				visible={modalVisible}
				closeMaskOnClick={false}
				onClose={() => hideModal()}
				borderRadius={10}
			>
			</Rodal>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor="rose" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 100,
				}}
			/>
			<Parallax
				image={require('assets/img/bg6.jpg')}
				filter="rose"
				className={classes.pageHeader}
			></Parallax>
			<div
				className={classNames(
					classes.section,
					classes.sectionGray,
					classes.sectionGrayExtended
				)}
			>
				<div className={classes.container}>
					<div
						className={classNames(classes.main, classes.mainRaised, classes.content)}
					>
						<GridContainer>
							<GridItem md={12} sm={12}>
							<div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' /* 16:9 aspect ratio */ }}>
								{!imageLoaded && <div variant="rectangular" width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0 }} />}
								<img
								src={require('assets/img/mge2.jpg')}
								alt=""
								onLoad={handleImageLoad}
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									display: imageLoaded ? 'block' : 'none'
								}}
								/>
							</div>
							</GridItem>
							
							<GridItem md={6} sm={12}>
								<h2 className={classes.title}>Here’s what you’re getting with your purchase of Meet Girls Everywhere:
								</h2>
								{getting_text.map((item, index) => {
									return <Box display="flex" alignItems="flex-start" mb={2}>
												<CheckCircle color="secondary" style={{ marginRight: '8px', marginTop: '4px' }} />
												<Box>
												<Typography variant="body1" component="div" fontWeight="bold">
													{item.title}: { } <span style={{ color: "#000000" , textDecoration: "line-through"}}>{item.old_price}</span> <span style={{ color: "#E10032" }}>{item.new_price}</span>
												</Typography>
												<Typography variant="body2" component="div">
													{item.description}
												</Typography>
												</Box>
											</Box>
								})}
							</GridItem>
							<GridItem md={6} sm={12}>
								<h2 className={classes.title}>Meet Girls Everywhere</h2>
								
								<Grid item md={12} sm={12}>
									<FormControl component="fieldset">
										<RadioGroup row>
											<FormControlLabel value="single" control={<Radio checked={true}/>} label="$297" />
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item md={12} sm={12}>
									<PaymentForm productid="c6RF045j7f9zAiQvXV6n"/>
								</Grid>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
			<Footer
				content={
					<div className={classes.footer}>
						<div className={classes.container}>
							<div className={classes.left}>
								<List className={classes.list}>
									<ListItem className={classes.inlineBlock}>
										<a href="/" className={classes.block}>
											About
										</a>
									</ListItem>
									<ListItem className={classes.inlineBlock}>
										<a href="/blog-post" className={classes.block}>
											My Philosophy
										</a>
									</ListItem>
								</List>
							</div>
							<div className={classes.right}>
								&copy; {1900 + new Date().getYear()} , made with{' '}
								<Favorite className={classes.icon} /> by{' '}
								<a
									href="https://www.linkedin.com/in/sorin-gifei-frontend"
									target="_blank"
									className={classes.aClasses}
									rel="noopener noreferrer"
								>
									Sorin Gifei
								</a>{' '}
								for a better web.
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
}
