import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import InfoAreaTwo from 'components/InfoArea/InfoAreaTwo.js';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram'
import Box from 'components/Grid/GridItem.js';

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
	const classes = useStyles();
	return (
		<div className={classes.section}>
			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={12}>
					<a href='/blog-post' style={{ textDecoration: 'none' }}>
						<h2 className={classes.title}>Who Am I and How Can I Help You?</h2>
						<h3 className={classes.description}>
							The Best Way to Get to Know Me Is to Read
						</h3>
						<br></br>
						<Button color='danger' size='lg'>
							<LibraryBooks /> My Philosophy Page
						</Button>
					</a>
					<br></br>
				</GridItem>
			</GridContainer>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={4} md={4}>
						<div
							style={{ textDecoration: 'none', color: 'inherit' }}
						>
							<InfoArea
								title='My Content'
								description="If you want a deeper look into my practical advice, check out my YouTube or Instagram."
								icon={PlayCircleFilledIcon}
								iconColor='info'
								vertical
							/>
						</div>
						
						<div style={{ display: 'block', textAlign: 'center', justifyContent: 'center' , color: 'black', height: "2em" }}>
								<a href="https://www.youtube.com/@realhectorcastillo" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', marginRight: '8px' }}>
									<YouTubeIcon style={{ fontSize: '60'}}/>
								</a>
								<a href="https://www.instagram.com/realhectorcastillo/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
									<InstagramIcon style={{ paddingLeft: '20px', fontSize: '60'}}/>
								</a>
							</div>
					</GridItem>
					<GridItem xs={12} sm={4} md={4}>
						<div
							style={{ textDecoration: 'none', color: 'inherit' }}
						>
							<InfoAreaTwo
								title="Courses"
								style={{ textAlign: 'left', items: 'left' }}
								description={
									<>
									{`I have two courses available right now… \n 1. My day game course: Meet Girls Everywhere, which teaches you how to…meet girls everywhere. Pretty clever right? \n 2. My texting book: What She Really Means, which teaches you how to text girls and covers almost every possible texting scenario you can find yourself in…`
										.split('\n')
										.map((text, index) => (
										<React.Fragment key={index} style={{ textAlign: 'left', items: 'left' }}>
											{text}
											<br />
										</React.Fragment>
										))}
									</>
								}
								icon={ReceiptIcon}
								iconColor="danger"
								vertical
								link='/product-page2'
							/>
						</div>
					</GridItem>
					<GridItem xs={12} sm={4} md={4}>
						<a href='/pricing' style={{ textDecoration: 'none', color: 'inherit' }}>
							<InfoArea
								title='Personal Coaching'
								description='There is nothing better than working with me 1-on-1. If you want to learn fast and have a decade of seduction and relationship wisdom shared with you, then click this text to go to the coaching page or click on “Coaching” at the top of this page.'
								icon={WhatsAppIcon}
								iconColor='success'
								vertical
							/>
						</a>
					</GridItem>
				</GridContainer>
			</div>
		</div>
	);
}
