import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/Footer.js';
import Button from 'components/CustomButtons/Button.js';
import Accordion from 'components/Accordion/Accordion.js';
import productStyle from 'assets/jss/material-kit-pro-react/views/productStyle.js';
import logo from 'assets/img/logo.png';
import product1 from 'assets/img/examples/product1.jpg';
import product2 from 'assets/img/examples/product2.jpg';
import product4 from 'assets/img/examples/product4.jpg';
import wsrm from 'assets/img/wsrm.jpg';
import { getAuth, getIdToken } from 'firebase/auth';
import axios from 'axios';
import typographyStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.js';
import toast, { Toaster } from 'react-hot-toast';
import Rodal from 'rodal';
import NavPills from 'components/NavPills/NavPills.js';
import pillsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js';
import Dashboard from '@material-ui/icons/Dashboard';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import useStylesVideo from '../PricingPage/Sections/SectionPricing';

const useStyles = makeStyles(productStyle);
const useStylesTab = makeStyles(pillsStyle);
const useStylesTypography = makeStyles(typographyStyle);
const styles = {
	footer: {
		flexShrink: 0,
		marginTop: 'auto',
	},
};
const useStylesCourse = makeStyles((theme) => ({
	// ...other styles...
	customStyle2: {
		maxWidth: '96% !important',
	},
	customStyle: {
		margin: '-52vh 0 0 !important',
		width: '100% !important',
	},
}));

export default function DashboardPage() {
	const classesCourse = useStylesCourse();
	const classesVideos = useStylesVideo();
	const pills = useStylesTab();
	const classes = useStyles();
	const typographyClasses = useStylesTypography();
	const [modalVisible, setModalVisible] = React.useState(false);
	const [stripeElements, setStripeElements] = React.useState(null);
	const [stripe, setStripe] = React.useState(null);
	// const loadingToastIdRef = React.useRef(null);

	const history = useHistory();

	const showModal = () => {
		setModalVisible(true);
		// setStripeElements(null); // Reset stripeElements state
		// setStripe(null); // Reset stripe state
		toast.success('Payment Initialized! Please wait...');
	};

	const hideModal = () => {
		setModalVisible(false);
		toast.dismiss();
		// setStripeElements(null);
		// setStripe(null);
		toast.error('Payment cancelled.');
	};

	// const showLoadingToast = (message) => {
	// 	if (!loadingToastIdRef.current) {
	// 		loadingToastIdRef.current = toast.loading(message);
	// 	} else {
	// 		toast.update(loadingToastIdRef.current, { render: message });
	// 	}
	// };

	// const hideLoadingToast = () => {
	// 	if (loadingToastIdRef.current) {
	// 		toast.dismiss(loadingToastIdRef.current);
	// 		loadingToastIdRef.current = null;
	// 	}
	// };

	const handleKeyDown = React.useCallback((event) => {
		if (event.keyCode === 27) {
			// ESC key
			hideModal();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	const buyTest = async (productId) => {
		const auth = getAuth();
		const user = auth.currentUser;

		if (!user) {
			history.push('/login-page');
			return;
		}

		// showLoadingToast('Payment Initialized! Please wait...');

		getIdToken(user).then(async (idToken) => {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/initPayment`,
				{
					productId: productId,
				},
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			showModal();

			const stripe = window.Stripe(
				process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
			);
			const elements = stripe.elements({
				appearance: { theme: 'stripe' },
				clientSecret: res.data.clientSecret,
			});
			const paymentElement = elements.create('payment');
			paymentElement.mount('#payment-element');

			setStripe(stripe);
			setStripeElements(elements);
		});
	};

	const pay = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		const auth = getAuth();
		const user = auth.currentUser;

		// showLoadingToast('Processing payment...');

		const { error } = await stripe.confirmPayment({
			elements: stripeElements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: user.name,
						email: user.email,
					},
				},
			},
			redirect: 'if_required',
		});

		// hideLoadingToast();

		if (error) {
			if (error.type === 'card_error' || error.type === 'validation_error') {
				toast.error(error.message);
			} else {
				toast.error('Error processing payment.');
			}
		} else {
			hideModal();
			toast.success(
				'Payment successful, please check your email/account for confirmation.'
			);
		}
	};
	const images = {
		wsrm: {
			original: wsrm,
			thumbnail: wsrm,
		},
		product4: {
			original: product4,
			thumbnail: product4,
		},
		product1: {
			original: product1,
			thumbnail: product1,
		},
		product2: {
			original: product2,
			thumbnail: product2,
		},
	};
	return (
		<div className={classes.productPage}>
			<Toaster position='bottom-center' />
			<Rodal
				visible={modalVisible}
				closeMaskOnClick={false}
				onClose={() => hideModal()}
				borderRadius={10}
			>
				<div className={typographyClasses.typo}>
					<h3>Pay With Card</h3>
				</div>
				<div id='payment-element'></div>
				<div id='card-errors' className='mt-3 text-danger' role='alert'></div>

				<div
					style={{
						display: 'flex',
						marginTop: '10px',
						justifyContent: 'space-between',
					}}
				>
					<Button type='button' round onClick={() => hideModal()}>
						Close
					</Button>

					<Button type='button' color='rose' round onClick={pay}>
						Pay
					</Button>
				</div>
			</Rodal>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt='logo' style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor='rose' />}
				fixed
				color='transparent'
				changeColorOnScroll={{
					height: 100,
				}}
			/>
			<Parallax
				image={require('assets/img/bg6.jpg')}
				filter='rose'
				className={classNames(classes.pageHeader, classes.parallaxFullHeight)}
			></Parallax>
			<div
				className={classNames(
					classes.section,
					classes.sectionGray,
					classes.content
				)}
			>
				<div className={classNames(classesCourse.customStyle2, classes.container)}>
					<div
						className={classNames(
							classes.main,
							classes.mainRaised,
							classes.mainContainer,
							classesCourse.customStyle
						)}
					>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<NavPills
									horizontal={{
										tabsGrid: { xs: 12, sm: 2, md: 2 },
										contentGrid: { xs: 12, sm: 10, md: 10 },
									}}
									color='rose'
									tabs={[
										{
											tabButton: '1: Why Day Game',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															Why is meeting girls during the day the BEST way to get dates,
															sex, and girlfriends?
														</h4>
														<p>
															Most guys these days resort to dating apps, nightclubs, bars,
															their workplace, or their social circles to meet girls.
														</p>
														<p>As you’ll find out, there’s a better way to meet girls.</p>
														<p>A way that requires no money or cool group of friends.</p>

														<h4>In this lesson you’ll learn:</h4>
														<ul>
															<li>
																Why the way most men meet girls isn’t reliable or effective
															</li>
															<li>Why just approaching a girl is a high value move</li>
															<li>How you find the highest quality women during the day</li>
															<li>The biggest myths about meeting girls during the day</li>
															<li>
																How meeting girls everywhere is taking charge of your dating
																life
															</li>
															<li>
																The trajectory of this course and its goal to give you the
																dating life you want
															</li>
														</ul>

														<p>
															This video will give you the confidence to approach girls during
															the day by dispelling any doubts you have and giving you tons of
															reasons to do it (but you don’t need any more reason than to meet
															beautiful women, right?)
														</p>

														<p>
															Even if you’re already approaching girls during the day, give
															this video a watch.
														</p>

														<div class='homework'>
															<h4>Homework 1:</h4>
															<p>
																Write down the three worst situations you’ve encountered at
																parties, dating women who party, or dating a girl from your
																social circle.
															</p>

															<h4>Homework 2:</h4>
															<p>
																Write down your three biggest fears approaching girls during the
																day.
															</p>
															<p>
																Hold on to Homework 2 and we’ll revisit it after you do your
																first ten approaches.
															</p>
														</div>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '2: Approach Anxiety',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															Approach anxiety is the biggest barrier between you and success.
															I will give you some insights into defeating this fear and some
															tricks to hack the anxiety itself.
														</h4>

														<h4>In this lesson you’ll discover:</h4>
														<ul>
															<li>
																That effort is the only thing that will bring you results
															</li>
															<li>How rejection isn’t as harsh as you think it is</li>
															<li>How to “objectify” the interaction to get past anxiety</li>
															<li>
																How to visualize a future with her that will drown the anxiety
															</li>
															<li>How to do warm up approaches to take the pressure off</li>
															<li>Why it only takes one approach to change it all</li>
														</ul>
														<br />
														<h4>Homework:</h4>
														<p>
															I want you to say “hello” to five pretty women as you go about
															your life this week. You don’t even need to approach them yet. I
															simply want you to see that nothing bad happens if you simply say
															hello and that you may even get a smile…or more.
														</p>
														<p>
															Reflect on that experience and ask yourself – did anything bad
															happen? If not, then why do you have a fear of approaching?
														</p>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '3: Fundamentals',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															Just as approaching is the key to actually getting results, as we
															found out in Module 2, we’re going to learn why fundamentals are
															the key to making the approach actually have some effect.
														</h4>
														<p>
															Lots of guys think you need to be a model or tall or have
															six-pack abs to get girls – we’re going to go over why this is a
															false assumption…
														</p>
														<p>These traits obviously are helpful!</p>
														<p>
															And many things that make you a more attractive guy can be earned
															by you with just a little bit of work and discipline.
														</p>

														<h4>In this lesson, you’ll learn:</h4>
														<ul>
															<li>How seduction is an experience mostly of the five senses</li>
															<li>How to make your voice more sexy and cool</li>
															<li>
																How to improve your eye contact and how much you should look at
																her
															</li>
															<li>
																The importance of facial expressions and how they reveal you to
																her
															</li>
															<li>How some fundamentals are “passive”</li>
															<li>How to manage your body language and hand gestures</li>
															<li>
																That you must earn your attractiveness, especially if you didn’t
																hit the genetic lottery (and even if you did, how to earn even
																MORE attraction)
															</li>
														</ul>
														<h4>Homework:</h4>
														<p>
															Make a list of fundamentals that you have that you are satisfied
															with and that you are not satisfied with.
														</p>
														<p>
															Then, with those you’re not satisfied with, can they be changed?
														</p>
														<p>If they can’t, then leave it alone.</p>
														<p>
															If they can, then set a goal for yourself for the next three
															months (e.g., if you want to put on muscle, how much muscle you
															want to gain). Write that goal down.
														</p>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '4: Signs & Signals',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															Approach invitations aren’t necessary. You should be able to
															approach a girl anywhere, anytime. That’s why it’s called Meet
															Girls Everywhere!
														</h4>
														<p>
															But isn’t it great to know that if you DO approach her, there’s a
															SUPER high chance she’s going to respond well and flirt back and
															almost definitely say “YES” to a date?
														</p>
														<p>
															So, let’s find out what signs women will give when they’re HOPING
															for you to approach them!
														</p>

														<h4>In this lesson, you’ll learn:</h4>
														<ul>
															<li>How to view all beautiful women you pass by in your life</li>
															<li>
																Why being limited to approach invitations isn’t the right
																mindset
															</li>
															<li>Tons of approach invitations to look out for</li>
															<li>
																How to tell if it’s an approach invitation if you’re not sure
															</li>
															<li>
																How to be more conscious of approach invitations if you don’t
																yet see them
															</li>
															<li>
																How to increase the number of approach invitations you receive
															</li>
															<li>How to recognize “passive approach invitations”</li>
															<li>How to recognize “buzz off” signals</li>
														</ul>

														<p>
															With this insight, you’ll have so much more fun approaching
															women, because you’ll know these women already like you and your
															chances of success will SKYROCKET.
														</p>

														<h4>Homework:</h4>
														<p>
															Go out for one hour (or as you’re out and about) and count the
															number of approach invitations and buzz off signals you see. You
															don’t need to approach yet, but simply see how much interest
															there is for you.
														</p>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '5: Times & Places',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															We want you to be able to meet girls everywhere, but some WHERE’S
															are better than others.
														</h4>
														<p>So where should you go to meet girls?</p>

														<h4>In this lesson, you’ll learn:</h4>
														<ul>
															<li>My favorite places to meet girls</li>
															<li>The types of girls you’ll find in each location</li>
															<li>The place where MOST girls will be</li>
															<li>Why some locations are more discreet than others</li>
															<li>What TIME to go out and meet girls for maximum results</li>
														</ul>

														<p>
															In the end, it only matters if you like her and she likes you,
															but knowing where and when to go can make your journey in finding
															that new girlfriend or lay SO MUCH EASIER.
														</p>

														<div class='homework'>
															<h4>Homework:</h4>
															<p>
																Go out to the locations mentioned in the video and count how
																many girls you see that you find really attractive. The place
																with the most attractive women is where you should be going more
																frequently!
															</p>
														</div>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '6: How to Approach',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															We got our fundamentals down, we know where to meet girls, and we
															know how to spot girls who really want to talk to us.
														</h4>
														<p>Now, it’s time to approach.</p>
														<p>
															The approach itself only lasts a few seconds, but it’s crucial to
															the seduction. Her first impression is going to create the
															foundation for the rest of your relationship with her.
														</p>

														<h4>
															Let’s make those first few seconds an experience that makes her
															go “Wow,” and smile for days, months, or even years to come when
															she remembers first having met you.
														</h4>

														<h4>In this lesson, you’ll learn:</h4>
														<ul>
															<li>
																Why a “Raw Open” is the best way to authentically create a bond
															</li>
															<li>
																How “Approach Angles” make your approaches so much more smooth
															</li>
															<li>How to get her attention without being too aggressive</li>
															<li>Ways to make approaches feel natural and organic</li>
															<li>What to do after you compliment her</li>
															<li>How to have the best tone of voice when approaching</li>
															<li>How to tailor your approach to each girl</li>
															<li>Ways to make women comfortable and open to your approach</li>
														</ul>

														<p>
															Keep improving these first few seconds of the approach and you’ll
															see your seductions go so much more smoothly and you’re getting
															so many more dates and more sex.
														</p>

														<div class='homework'>
															<h4>Homework:</h4>
															<p>
																Go approach five girls. If you don’t know what to do after you
																give the compliment, that’s okay, I simply want you to approach.
															</p>
															<p>Write down the experience with as much detail as you can.</p>
														</div>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '6.1: Vibe & Speed',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															When you make your approach, you'll discover women can have
															different vibes and operate at different speeds. This is one area
															you definitely don't want to be 'one size fits all' -- unless you
															want to miss out on girls who don't match that size!
														</h4>
														<p>
															So, in this lesson, our focus is squarely on matching your vibe
															to that of the girl you approach.
														</p>

														<h4>In this lesson, you’ll learn:</h4>
														<ul>
															<li>The 4 different dimensions of a man's 'approach vibe'</li>
															<li>
																Why it's so vital to MATCH the energy of the girl you approach
															</li>
															<li>
																The role comfort plays in disarming daytime approach 'weirdness'
															</li>
															<li>
																Adapting your energy to women vs. trying to adapt them to yours
															</li>
															<li>
																Signs a woman is in a 'low energy' mode: speed, mannerisms, etc.
															</li>
															<li>
																Demonstration of a low energy approach: what it looks like
															</li>
															<li>
																Demonstration of a high energy approach: what this looks like
															</li>
															<li>
																Signs a woman is in a playful vibe vs. signs she's feeling
																serious
															</li>
															<li>
																How to present yourself as serious (to match a girl who is this)
															</li>
															<li>
																Demonstration of a serious approach: how to pull this off
																smoothly
															</li>
															<li>
																Demonstration of a playful approach: how to meet her in a
																playful way
															</li>
															<li>
																Why the first moment is superficial (and why you need to accept
																that)
															</li>
														</ul>

														<p>
															Even if it feels a little forced, take some time to learn to
															adapt yourself to the woman you approach. As you get better at
															this, you open up options with many more women... and allow
															yourself to get beyond the early superficial moments into the
															meatier parts of the courtship where you can really be yourself.
														</p>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '6.2: Indirect Openers',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															Direct openers are great. But there are times they don't make
															sense (or might even be awkward). How do you open in those
															situations? With indirect.
														</h4>
														<p>
															Situations that call for indirect (rather than direct) openers:
														</p>
														<ul>
															<li>
																The 'team work' indirect opener -- get her helping you out
															</li>
															<li>The 3 elements of a great situational opener</li>
															<li>Why sincerity plays such a big role in opener success</li>
															<li>
																Indirect comments and indirect questions: doesn't matter if they
																sound 'stupid'
															</li>
															<li>
																Constructing a good transition from an indirect open to a name
																exchange
															</li>
															<li>
																Looking for opportunities to open stationary girls who are busy
																/ occupied
															</li>
															<li>
																Slow opening: making gradual offhand comments to a nearby woman
															</li>
															<li>
																Why direct opening works better in the majority of situations
															</li>
															<li>
																When you should save indirect opening for to use it most
																effectively
															</li>
														</ul>

														<p>
															Use indirect for the situations that warrant. But don't overuse
															it; direct openers remain overall the most powerful, versatile,
															efficient openers in your arsenal.
														</p>

														<div class='homework'>
															<h4>Homework:</h4>
															<p>
																Try out five (5) indirect approaches if a direct approach
																doesn't make sense.
															</p>
															<p>
																But do not use the "Where is Starbucks?" or "What time is it?"
																indirect approaches.
															</p>
															<p>
																Make your indirect openers spontaneous and original (unless of
																course you are actually lost and need help finding where
																something is!).
															</p>
														</div>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '7: How to Converse',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															You’ve approached her. Now what? What do you say next? This is
															where we learn the art of conversation and how to keep the
															seduction going.
														</h4>
														<p>Beautiful in its beginning. Beautiful in its middle.</p>

														<h4>In this lesson you will learn:</h4>
														<ul>
															<li>Why saying SOMETHING is better than saying NOTHING</li>
															<li>
																The Golden Questions of Conversation that help you create
																conversation from the simplest information
															</li>
															<li>The Three factors of good conversation</li>
															<li>The Four Fundamentals of Conversation</li>
															<li>How much eye contact to keep when talking or listening</li>
															<li>
																Some fun and simple facial expression techniques that give your
																conversation life
															</li>
															<li>
																Why conversation doesn’t always need to be intellectual or deep
															</li>
															<li>Why your conversations are boring</li>
														</ul>

														<p>
															Once you implement these simple and clear strategies into your
															conversations, you will begin creating organic and interesting
															conversation where before there was only silence or awkwardness.
															You will learn how conversation is something that is built in the
															moment by listening and adapting.
														</p>

														<div class='homework'>
															<h4>Homework:</h4>
															<p>
																Approach 5 more girls and now try to hold conversation with her
																for 3-5 minutes without it getting too awkward or running out of
																things to say. If you mess up, that’s fine, try with another
																girl! Post your results on the forum.
															</p>
														</div>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '8: How to Close',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<span>
															<h4>
																We’re approaching and talking. Girls are smiling, listening, and
																want to keep talking to you! But the entire purpose of this
																course is to get you dates, sex, and girlfriends!
															</h4>
															<p>
																We met her, talked to her, now we need to ASK HER OUT. But how
																do you go from talking…to asking her out? And what do you say,
																exactly?
															</p>

															<h4>In this lesson, you’ll learn:</h4>
															<ul>
																<li>The Dominance Hierarchy of Asking Girls Out</li>
																<li>The right mentality to have before asking her out</li>
																<li>
																	How to get the first text out of the way while you’re with her
																</li>
																<li>
																	Ways to make sure it’s not awkward while you’re putting in her
																	number or contact information
																</li>
																<li>The right timing to ask her out</li>
																<li>
																	How to do a “Raw Close” and make asking her out organic and
																	easy to say “YES” to
																</li>
															</ul>

															<p>
																Now, we’ve got the process going. It was beautiful in its
																beginning, beautiful in its middle, and beautiful in its end.
																She said YES to a date with you and now you got a girl who had
																an AMAZING experience with you, which will not only GREATLY
																reduce the chances of her flaking, but she’ll be EXCITED to see
																you again.
															</p>

															<div class='homework'>
																<h4>Homework:</h4>
																<p>
																	Ask out 5 girls. Write down the entire experience from the open
																	to the close and post it on the forum.
																</p>
															</div>
														</span>
													</GridItem>
												</GridContainer>
											),
										},
										{
											tabButton: '9: Advanced Game',
											tabContent: (
												<GridContainer>
													<GridItem md={12} sm={12}>
														<Card plain pricing>
															<CardBody pricing>
																<div className={classesVideos.iframeContainer}>
																	<iframe
																		width='100%'
																		height='650'
																		src='https://www.youtube.com/embed/b2HeQNuwzH0'
																		title='YouTube video player'
																		frameborder='0'
																		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
																		allowfullscreen
																	></iframe>
																</div>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem md={12} sm={12}>
														<h4>
															You’ve got the entire process to meet girls everywhere and get
															dates and girlfriends, but now you want to take it to the next
															level. That’s what this module is for. For those guys who really
															want to do some fun stuff.
														</h4>
														<p>In this lesson, you’ll learn:</p>
														<ul>
															<li>
																How to approach groups of girls and have her friends not only
																approve of you but HELP you seduce their friend
															</li>
															<li>How to have an instant date</li>
															<li>
																How to kiss girls that you just met and look for girls who are
																ready for it even after only MINUTES meeting you
															</li>
															<li>
																How to take girls home immediately after meeting them (and why
																most girls are hesitant to do it, but not against it!)
															</li>
															<li>How to become a Day Game artist</li>
															<li>
																How to free yourself COMPLETELY from the constraints of social
																norms and create an insane dating life that most guys only dream
																of having
															</li>
														</ul>
														<p>
															Now you’ve got all the tools you need to get tons of gorgeous,
															cool, and sexy girls ready to see you again for a date, and,
															eventually, become your lovers and girlfriends.
														</p>

														<p>
															Go and implement everything I’ve taught you and become the master
															of meeting girls everywhere!
														</p>

														<div class='homework'>
															<h4>Homework 1 (for beginner/intermediate):</h4>
															<p>
																Have an instant date with a girl and then kiss her during the
																date.
															</p>

															<h4>Homework 2 (for advanced):</h4>
															<p>Have an instant date with a girl and bring her home.</p>
														</div>
													</GridItem>
												</GridContainer>
											),
										},
									]}
								/>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
			<div className={classes.footer} style={styles.footer}>
				<Footer
					content={
						<div>
							<div className={classes.left}>
								<List className={classes.list}>
									<ListItem className={classes.inlineBlock}>
										<a href='/' className={classes.block} rel='noopener noreferrer'>
											About
										</a>
									</ListItem>
									<ListItem className={classes.inlineBlock}>
										<a href='/blog-post' className={classes.block}>
											My Philosophy
										</a>
									</ListItem>
								</List>
							</div>
							<div className={classes.right}>
								&copy; {1900 + new Date().getYear()} , made with{' '}
								<Favorite className={classes.icon} /> by{' '}
								<a
									href='https://www.linkedin.com/in/sorin-gifei-frontend'
									target='_blank'
									className={classes.aClasses}
									rel='noopener noreferrer'
								>
									Sorin Gifei
								</a>{' '}
								for a better web.
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
}
