import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, TextField, MenuItem , CircularProgress, Box } from '@material-ui/core';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { getAuth, getIdToken } from 'firebase/auth';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Lock } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Country }  from 'country-state-city';
const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    getCountryList();
  }, []);

  const handleChange = (event) => {
    setCountry(event.target.value);
  };
  
  const getCountryList = () => {
    const countriesList = Country.getAllCountries().map(country => ({
      code: country.isoCode,
      name: country.name
    }));
    setCountries(countriesList);
  }
  const validateCustom = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let e = false;
    let mail_str = "";
    let first_str = "";
    let last_str = "";
    let street1_str = "";
    let country_str = "";
    let city_str = "";
    if (!emailPattern.test(email) || email.length === 0) {
      e = true;
      mail_str = 'Invalid email format or required';
    }
    if (firstName.length === 0) {
      e = true;
      first_str = 'First Name required';
    }
    if (lastName.length === 0) {
      e = true;
      last_str = 'Last Name required';
    }
    if (street1.length === 0) {
      e = true;
      street1_str = 'Street Address required';
    }
    if (city.length === 0) {
      e = true;
      city_str = 'Street Address required';
    }if (country.length === 0) {
      e = true;
      country_str = 'Street Address required';
    }
    if (e) {
      setError({
        firstName: first_str,
        lastName: last_str,
        email: mail_str,
        street1: street1_str,
        city: city_str,
        country: country_str,
      });
    } else {
      setError(null);
    }
    return e;
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
      getIdToken(user).then(async (idToken) => {
        setToken(idToken);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getBillingInfo`,
            {},
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          );
          console.log(response.data);
          if (response.data.email)setEmail(response.data.email);
          if (response.data.firstName) {
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setStreet1(response.data.street1);
            setStreet2(response.data.street2);
            getCountryList();
            setCity(response.data.city);
            setStates(response.data.states);
            setCountry(response.data.country);
          }
        } catch (e) {
        }
      });
    }
  }, []);

  const handleSubmit = async (event) => {
    setPaymentStatus(null);
    event.preventDefault();
    if (!stripe || !elements) {
      setPaymentStatus("Stripe Error.");
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (validateCustom()) return;

    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/setBillingInfo`,
        { firstName, lastName, email, street1, street2, city, states, country},
        {
          headers: token ? {
            Authorization:   `Bearer ${token}`,
          }  : {},
        }
      );
      
      if(!token)setPaymentStatus(res.data.message);
      
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      
      if (error) {
        console.error(error);
        setLoading(false);
        return;
      }
      
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/initPayment`,
        { productId: props.productid, userId: res.data?.userId},
        {
          headers: token ? {
            Authorization:   `Bearer ${token}`,
          }  : {},
        });
      const { clientSecret } = await response.data;

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (confirmError) {
        console.error(confirmError);
        setLoading(false);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setPaymentStatus('Payment successful');
      } else {
        setPaymentStatus(`Payment failed: ${paymentIntent.error.message}`);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setPaymentStatus(e.message);
    }
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: '24px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Billing Information</Typography>
          <TextField
            variant='outlined'
            fullWidth
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            margin="dense"
            error={Boolean(error?.firstName)}
            helperText={error?.firstName}
          />
          <TextField
            variant='outlined'
            fullWidth
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            margin="dense"
            error={Boolean(error?.lastName)}
            helperText={error?.lastName}
          />
          <TextField
            variant='outlined'
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
            disabled={false}
            error={Boolean(error?.email)}
            helperText={error?.email}
          />
          <TextField
            variant='outlined'
            fullWidth
            label="Street Address"
            value={street1}
            onChange={(e) => setStreet1(e.target.value)}
            margin="dense"
            error={Boolean(error?.street1)}
            helperText={error?.street1}
          />
          <TextField
            variant='outlined'
            fullWidth
            label="Street Address Line 2 (optional)"
            value={street2}
            onChange={(e) => setStreet2(e.target.value)}
            margin="dense"
          />
          <TextField
            select
            label="Country"
            value={country}
            onChange={handleChange}
            variant='outlined'
            margin='dense'
            fullWidth
            error={Boolean(error?.country)}
          >
            {countries.length > 0 && countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant='outlined'
            fullWidth
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            margin="dense"
            error={Boolean(error?.city)}
            helperText={error?.city}
          />
          <TextField
            variant='outlined'
            fullWidth
            label="State"
            value={states}
            onChange={(e) => setStates(e.target.value)}
            margin="dense"
            error={Boolean(error?.states)}
            helperText={error?.states}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography style={{ paddingTop: '30px' }} variant="h6">
            Payment Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            alt="Product"
            src={require('assets/img/card_type.png')}
            style={{
              width: '30%',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          />
        </Grid>
        <Grid container justifyContent="center" spacing={0} variant='outlined'>
          <Grid item xs={1} style={{ alignItems: 'center', display:'flex'}}  className='card-element-outlined'>
            <Lock style={{ fontSize: 30 , color: green[500]}} />
          </Grid>
          <Grid item xs={11}  className='card-element-outlined'>
            <CardElement options={cardStyle} />
          </Grid>
        </Grid>
        {loading &&
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CircularProgress color="secondary" />
          </Grid>
        }
        <Grid item xs={12}>
          {paymentStatus && (
            <Typography color="secondary" variant="body1">
              {paymentStatus}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '30px' }}>
          <Button variant="contained" fullWidth color="secondary" type="submit" disabled={loading}>
            PAY
          </Button>
        </Grid>
        <Grid md={12} sm={12} style={{ textAlign: 'center' }}>
          <img
            alt="Product"
            src={require('assets/img/back_money.png')}
            style={{
              width: '70%',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          />
        </Grid>
        <Grid md={12} sm={12}>
          <Box display="flex" alignItems="flex-start" mb={2}>
            <Typography variant="body2" component="div">
              I’m sure you’re going to love having complete freedom to meet the most beautiful women anytime you want, but if for some reason you aren’t satisfied with the course, up to 30 days from the date of your purchase, you can email me at <a>support@hectorcastillodating.com</a> and I’ll refund your purchase in full.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentForm;