import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import manandwomen from 'assets/img/man-with-women.jpg';
import manandwife from 'assets/img/man-and-wife.jpg';
import Badge from 'components/Badge/Badge';
import InfoArea from 'components/InfoArea/InfoArea';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import MoodIcon from '@material-ui/icons/Mood';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import projectsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js';
import PublicIcon from '@material-ui/icons/Public';
import SectionJavascript from 'views/ComponentsPage/Sections/SectionJavascript';
import Datetime from 'react-datetime';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import Mail from '@material-ui/icons/Mail';
import Phone from '@material-ui/icons/Phone';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { useEffect } from 'react';
import classNames from 'classnames';
import hc from 'assets/img/hectorClassroom.png';
import styles3 from 'assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js';
import javascriptStyles from 'assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js';
import { useState } from 'react';
import axios from 'axios';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import Check from '@material-ui/icons/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const useStyles = makeStyles(projectsStyle);
const useStyles2 = makeStyles(javascriptStyles);
const useStyles3 = makeStyles(styles3);
const useStyles4 = makeStyles((theme) => ({
	subscribeButton: {
		marginTop: '20px',
	},
}));

export default function SectionTeam() {
	const [signupModal, setSignupModal] = React.useState(false);
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [name, setName] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const classes = useStyles();
	const classes2 = useStyles2();
	const classes3 = useStyles3();
	const classes4 = useStyles4();
	useEffect(() => {
		const timer = setTimeout(() => {
			setSignupModal(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, []);

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		console.log(email)
		if (validateEmail(email)) {
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/addSubscriber`, {
					email,
					phone,
					name
				});
				setIsEmailValid(true);
				setIsSubmitted(true);
				setShowWarning(false);

				setTimeout(() => {
					setSignupModal(false);
				}, 5000); 
			} catch (error) {
				console.log('Error in subscribing');
				setIsEmailValid(false);
				setShowWarning(true);
			}
		} else {
			setIsEmailValid(false);
			setShowWarning(true);
		}
	};

	return (
		<div className={classes.section}>
			<div className={classes.projects + ' ' + classes.projects4}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={
								classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
							}
						>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{/* <NewsletterModal
									signupModal={signupModal}
									setSignupModal={setSignupModal}
									classes2={classes}
									classes3={classesVideos}
									hc={hc}
								/> */}
								{/* BUTTON SIGNUP MODAL */}
								{/* <Button block round onClick={() => setSignupModal(true)}>
									<Assignment /> Newsletter
								</Button> */}
								{/* SIGNUP MODAL START */}
								<Dialog
									classes={{
										root: classes2.modalRoot,
										paper: classes2.modal + ' ' + classes2.modalSignup,
									}}
									open={signupModal}
									TransitionComponent={Transition}
									keepMounted
									onClose={() => setSignupModal(false)}
									aria-labelledby='signup-modal-slide-title'
									aria-describedby='signup-modal-slide-description'
								>
									<Card plain className={classes2.modalSignupCard}>
										<DialogTitle
											id='signup-modal-slide-title'
											disableTypography
											className={classes2.modalHeader}
										>
											<Button
												simple
												className={classes2.modalCloseButton}
												key='close'
												aria-label='Close'
												onClick={() => setSignupModal(false)}
												style={{ bottom: '20px' }}
											>
												{' '}
												<Close className={classes2.modalClose} />
											</Button>
										</DialogTitle>
										<DialogContent
											id='signup-modal-slide-description'
											className={classes2.modalBody}
										>
											<div
												className={classNames(
													classes3.subscribeLine,
													classes3.subscribeLineImage
												)}
												style={{ backgroundImage: `url(${hc})` }}
											>
												<div className={classes3.container}>
													<GridContainer>
														<GridItem
															xs={12}
															sm={6}
															md={6}
															className={classNames(classes3.mlAuto, classes3.mrAuto)}
														>
															<div className={classes3.textCenter}>
																<h3 className={classes3.title}>Stay Connect (And Get Some Free Stuff)</h3>
																<p className={classes3.description}>
																	Subscribe to my newsletter and I'll send you two free things: an online dating guide and my manual for my attraction system of love, lust, and trust.
																</p>
															</div>
															<Card raised className={classes3.card}>
																<CardBody className={classes3.cardBody}>
																	<form onSubmit={onFormSubmit}>
																		<GridContainer>
																			{!isSubmitted && (
																				<>
																					<GridItem xs={12}>
																						<CustomInput
																							id='emailPreFooter'
																							formControlProps={{
																								fullWidth: true,
																								className: classes.cardForm,
																							}}
																							inputProps={{
																								startAdornment: (
																									<InputAdornment position='start'>
																										<Mail />
																									</InputAdornment>
																								),
																								placeholder: 'Your Email...',
																								value: email,
																								onChange: (e) => setEmail(e.target.value),
																							}}
																						/>
																					</GridItem>
																					<GridItem xs={12}>
																						<CustomInput
																							id='namePreFooter'
																							formControlProps={{
																								fullWidth: true,
																								className: classes.cardForm,
																							}}
																							inputProps={{
																								startAdornment: (
																									<InputAdornment position='start'>
																										<PermContactCalendarIcon />
																									</InputAdornment>
																								),
																								placeholder: 'Your Name',
																								value: name,
																								onChange: (e) => setName(e.target.value),
																							}}
																						/>
																					</GridItem>
																					<GridItem xs={12}>
																						<CustomInput
																							id='phonePreFooter'
																							formControlProps={{
																								fullWidth: true,
																								className: classes.cardForm,
																							}}
																							inputProps={{
																								startAdornment: (
																									<InputAdornment position='start'>
																										<Phone />
																									</InputAdornment>
																								),
																								placeholder: 'Your Phone Number(optional)',
																								value: phone,
																								onChange: (e) => setPhone(e.target.value),
																							}}
																						/>
																					</GridItem>
																					<GridItem xs={12}>
																						<Button
																							color='primary'
																							block
																							className={classes4.subscribeButton}
																							type='submit'
																						>
																							Subscribe
																						</Button>
																					</GridItem>
																				</>
																			)}
																			{isSubmitted && isEmailValid && (
																				<SnackbarContent
																					message={
																						<span>
																							<b>SUCCESS ALERT:</b> Thank you for subscribing! Check
																							your email for further instructions.
																						</span>
																					}
																					close
																					color='success'
																					icon={Check}
																				/>
																			)}
																			{showWarning && (
																				<SnackbarContent
																					message={
																						<span>
																							<b>WARNING ALERT:</b> Please enter a valid email address.
																						</span>
																					}
																					close
																					color='danger'
																					icon='info_outline'
																				/>
																			)}
																		</GridContainer>
																	</form>
																</CardBody>
															</Card>
														</GridItem>
													</GridContainer>
												</div>
											</div>
										</DialogContent>
									</Card>
								</Dialog>
								{/* SIGNUP MODAL END */}
							</GridItem>
							<h2 className={classes.title}>How Can I Help You?</h2>
							<h5 className={classes.description}>
								Do you want to be a player or do you want to find a girlfriend/wife?<br></br>								
								Or maybe you want to be a player first and then also find your dream girl to settle down with?<br></br>
								I can help with both…
							</h5>
							<div className={classes.sectionSpace} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
							<Card
								// background
								className={classes.card4}
								style={{
									backgroundImage: `url(${manandwomen})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									height: '100%',
									width: '100%',
								}}
							>
								<CardBody background className={classes.cardBody4}></CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
							<InfoArea
								className={classes.info4}
								title='Be a moral seducer'
								description='Being an ethical playboy not only is the right thing to do, it actually makes girls like you MORE. We will follow a strict code of ethics.'
								icon={MoodIcon}
								iconColor='info'
							/>
							<InfoArea
								className={classes.info4}
								title='Become “That Guy” '
								description='Women will smell pussy on you and know you are the kind of man who can give them a good time. You will literally transform into a different man – a better man.'
								icon={AccessibilityNewIcon}
								iconColor='primary'
							/>
							<InfoArea
								className={classes.info4}
								title='It Takes Skill'
								description="At the end of the day, being a player requires a lot of skills. The more girls and the higher quality of girls you want, the more skills you will need. Becoming “That Guy” means having the skills only “That Guy” has."
								icon={SupervisedUserCircleIcon}
								iconColor='danger'
							/>
						</GridItem>
					</GridContainer>
					<hr />
					<GridContainer>
						<GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
							<InfoArea
								className={classes.info4}
								title='The Skill of Love'
								description="Like seduction, successful relationships require skill. I will teach you how to become a beacon of love, kindness, and sexiness that make her the happiest and luckiest woman in the world."
								icon={FavoriteIcon}
								iconColor='rose'
							/>
							<InfoArea
								className={classes.info4}
								title='Become worthy of her'
								description='If you want a queen, you must be a king. This has less to do with status, money, or achievements and much more to do with your character, virtue, and communication skills.'
								icon={StarHalfIcon}
								iconColor='success'
							/>
							<InfoArea
								className={classes.info4}
								title='Finding the Diamond in the Dirt'
								description='There are tons of beautiful girls out there, but many of them are rotten inside. Beauty is obviously a must, but you also need to see beneath her beauty and into her heart. Not everything that shines in the dirt is a diamond.'
								icon={PublicIcon}
								iconColor='info'
							/>
						</GridItem>
						<GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
							<Card
								// background
								className={classes.card4}
								style={{
									backgroundImage: `url(${manandwife})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									height: '100%',
								}}
							>
								<CardBody
									// background
									className={classes.cardBody4}
								>
									{/* <Badge color="rose">Find Your Perfect Partner</Badge> */}
									{/* <a onClick={(e) => e.preventDefault}>
										<h3 className={classes.cardTitle}>Improved Relationship Skills</h3>
										<p className={classes.cardDescription}>
											With my coaching, you{"'"}ll learn how to approach and communicate
											effectively with potential partners. You{"'"}
											ll gain the skills necessary to attract the partner you desire and
											build a meaningful relationship.
										</p>
									</a> */}
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
